import React, { Component } from "react"
import bellImage from "../images/bell.svg"
import alertIcon from "../images/alert-icon-small.svg"
import infoIcon from "../images/info-icon-small.svg"
import arrowDown from "../images/arrow-down.svg"
import closeIcon from "../images/close-icon.svg"

class AlertsSlider extends Component {

	constructor() {
	  var today = new Date();
	  var month = (today.getMonth() + 1).toString();
	  if (month.length < 2) {
	    month = `0${month}`;
	  }
	  var day = today.getDate().toString();
	  if (day.length < 2) {
	    day = `0${day}`;
	  }

	  var date = today.getFullYear() +'-'+month+ '-'+day;
	  super();
	  this.state = {
	    currentDateTime: date,
	    forcedPushAlerts: []
	  }
	}

	componentDidMount() {
	  let handleData = (hairdressers) => {

	      document.body.innerHTML = JSON.stringify(hairdressers);
	  }

	  let dataURL = "/assets/drupal_push_data.json";
	  fetch( dataURL,{
	        headers : {
	          'Content-Type': 'application/json',
	          'Accept': 'application/json'
	         }
	       }
	    )
	  .then(res => res.json())
	  .then(res => {
	    this.setState({
	      forcedPushAlerts: res
	    })
	  })

	}

	render() {

		// const curentAlerts = this.props.showAlerts

		var tempGroup, temp
		var alerts = this.props.allAlerts
		const isGlobal = this.props.globalPage
		var filteredAlerts
		alerts = alerts && alerts.filter(item => item.node.field_global_post !== true)

		if(isGlobal) {
			filteredAlerts = alerts.filter(item => item.node.field_global_post)
		} else if(this.props.filteredAlerts === true) {
			filteredAlerts = alerts
		} else {
			var alertsTemp = alerts && alerts.filter(
				item => {
							item.node.relationships.group_content__departments_group_node_alert !== null ||
							item.node.field_global_post ? (
								temp = true
							) : (
								temp = false
							)
							return temp
						}
				)

			filteredAlerts = []
			const groupId = this.props.groupId

			if(alertsTemp && alerts !== undefined) {
				filteredAlerts = alertsTemp.filter(item => {
					item.node.field_global_post ? (
						tempGroup = true
					) : (
						item.node.relationships.group_content__departments_group_node_alert && item.node.relationships.group_content__departments_group_node_alert.map((gr,key) => (
							key === 0 ? (tempGroup = false) : (null),
							gr.relationships.gid.drupal_internal__id === groupId ? (
								tempGroup = true
							) : (
								null
							)
						))
					)

					return tempGroup

				})
			}
		}

		if(this.state.forcedPushAlerts.alerts !== undefined && this.state.forcedPushAlerts.alerts.length > 0) {
			filteredAlerts = this.state.forcedPushAlerts.alerts.concat(filteredAlerts)
		}


		return (
			<div className="container alerts-container">
				{filteredAlerts.length > 0 ? (
					<div className="alerts-bar">
						<div className="notification-bell">
							<span className="number">{filteredAlerts.length}</span>
							<img src={bellImage} alt="bell" />
						</div>
						<div className="alerts-slider">
							<a className="arrow-down" title="see all alerts" aria-label="see all alerts" href="#"><img src={arrowDown} alt="arrow-down" /></a>
							<div className="uk-position-relative uk-light" tabIndex="-1">
								<ul id="switcher-nav" className="uk-subnav uk-subnav-pill uk-light" uk-switcher="animation: uk-animation-slide-bottom, uk-animation-slide-top; swiping: true">
									{filteredAlerts.map((alert,key) => (

										<li key={"dot"+key}><a href="#" aria-label="see filtered alerts">&nbsp;</a> </li>
									))}
								</ul>


								<ul id="all-alerts" className="uk-switcher alert-list uk-child-width-1-1" data-uk-height-match="row: false">
								{/*	<li><a href="#" id="autoplayer" data-uk-switcher-item="next">NEXT</a></li>*/}
									{filteredAlerts.map((alert,key) => (
										<li key={"alert" + key} >
											<div>
												{alert.node.field_alert_type === "1" ? (
													<img src={infoIcon} alt="info" />
												) : (
													alert.node.field_alert_type === "2" ? (
														<img src={alertIcon} alt="alert" />
													) : (
														<img src={infoIcon} alt="info" />
													)
												)}
												<p> {alert.node.field_alert_text}
													{alert.node.field_link && alert.node.field_link.uri ? (

														<a href={alert.node.field_link.uri.replace("internal:","")}> {alert.node.field_link.title}</a>
													) : (
														null
													)}

												</p>
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>



						<div className="alerts-slider expanded">
							<div className="uk-position-relative uk-light" tabIndex="-1">
								<a className="close-icon" href="#" title="close alerts" aria-label="close alerts"><span>Close</span> <img src={closeIcon} alt="close-icon" /></a>
								<ul className="alert-list uk-child-width-1-1">
									{filteredAlerts.map((alert,key) => (
										<li key={"alert" + key} >
											<div>
												{alert.node.field_alert_type === "1" ? (
													<img src={infoIcon} alt="info" />
												) : (
													alert.node.field_alert_type === "2" ? (
														<img src={alertIcon} alt="alert" />
													) : (
														<img src={infoIcon} alt="info" />
													)
												)}
												<p> {alert.node.field_alert_text}
													{alert.node.field_link && alert.node.field_link.uri ? (
														<a href={alert.node.field_link.uri.replace("internal:","")}> {alert.node.field_link.title}</a>
													) : (
														null
													)}
												</p>
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>


					</div>
				) : (
					null
				)}
			</div>


		)
	}
}

export default AlertsSlider

export const query = graphql`
  fragment node__alertEdgeFragment on node__alertEdge {
    node {
      title
      field_alert_text
      field_weight
      field_link {
        title
        uri
      }
      field_alert_type
      field_global_post
      relationships {
        group_content__departments_group_node_alert {
          relationships {
            gid {
              drupal_internal__id
            }
          }
        }
      }
    }
  }
`
