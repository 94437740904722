import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import AlertsSlider from "../components/alertsslider"
import SEO from "../components/seo"

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.nodeMember
    const alerts = this.props.data.allNodeAlert

    return (
        <Layout >
        <SEO
            title={currentPage.title}
            metaData={null}
            contentBody={currentPage.body}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />

        <div className="single-member">
          <section className="uk-padding-remove-top">
            <AlertsSlider allAlerts={alerts.edges} gruopId={null} globalPage={true} />
            <div className="container uk-margin-medium-top">
              <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
              <div className="entry-content">
                {currentPage.body && currentPage.body.processed ? (
                  <div dangerouslySetInnerHTML={{ __html: currentPage.body.processed }} />
                ) : (
                  null
                )}
              </div>
              {currentPage.field_wysiwyg_repeating[0] && currentPage.field_wysiwyg_repeating[0].value != null ? (
                <div className="past-appointments-wrp">
                  <h2>Appointment History</h2>
                  <div dangerouslySetInnerHTML={{ __html: currentPage.field_wysiwyg_repeating[0].value}} />
                </div>
              ) : (
                null
              )}


            </div>
          </section>
        </div>



        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    nodeMember(id: { eq: $id }) {
      title
      path {
        alias
      }
      field_wysiwyg_repeating {
        value
      }
    }

    allNodeAlert(filter: {field_global_post: {eq: true}},sort: {fields: field_weight, order: ASC}) {
      edges {
        node {
          title
          field_alert_text
          field_weight
          field_link {
            title
            uri
          }
          field_alert_type
          field_global_post
          relationships {
            group_content__departments_group_node_alert {
              relationships {
                gid {
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }

  }
`
